import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyInstaChat } from '@pixels/universal/model/apps/app-types';
import { InstaChatProjectMeta } from '@pixels/universal/model/apps/project-meta/insta-chat-project-meta';

@Injectable({ providedIn: 'root' })
export class InstaChatAppInfo extends AppInfo {
  public readonly appType = AppKeyInstaChat;
  public readonly serviceName = _('@인스타_친구_만들기@');
  public readonly appStoreUrl = signal(InstaChatProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${InstaChatProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? InstaChatProjectMeta.ios?.admobBannerVideoChatAppId : InstaChatProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@인스타_친구만들기@');
  public override readonly loginDescription = _('@지역별_또래별_n_인스타_친구찾기@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/instachat-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@인스타_친구_만들기@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
}
