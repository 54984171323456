import { AppKeyInstaChat } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'insta-chat-client';
const PROD_WEB_PUSH_KEY = 'BAlyvzISCXDuH7yd3g78Rqh5COkBsDapHrLW-n4b_10UAK_W7RWSyBkK9n5tV1KoRjnqPGw4GcqsRmpZY6Xb21U';
const DEV_WEB_PUSH_KEY = 'BPTnO_GyqNC9v8S9Gr7_inkwAlorWeLLgMalcG0n_T6k72oPd6sXIzL06fBhByMk9_7YoiYmKGiE6vyEI6f1tGo';

const ProdFirebaseConfig = {
  apiKey: 'AIzaSyCf8qS-Poey57vymEdRrXrmP-9jhKkjJt8',
  authDomain: 'insta-chat-prod.firebaseapp.com',
  projectId: 'insta-chat-prod',
  storageBucket: 'insta-chat-prod.appspot.com',
  messagingSenderId: '518115328856',
  appId: '1:518115328856:web:9d76a1805db78a491b9b2d',
  measurementId: 'G-JRVK7HYG2J'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyA1tHglqfCNrrKRKKlAcRvNOEhufXSWlzk',
  authDomain: 'insta-chat-dev2.firebaseapp.com',
  projectId: 'insta-chat-dev2',
  storageBucket: 'insta-chat-dev2.appspot.com',
  messagingSenderId: '255682473512',
  appId: '1:255682473512:web:ba49fdf056f968382c8f1d',
  measurementId: 'G-W3T3WBP54Y'
};
const PROD_TALK_PLUS_APP_ID = '473690dd-a955-4b4e-b048-054220be32a9';
const DEV_TALK_PLUS_APP_ID = 'd2a84f99-ad85-42d9-8d93-abade7f344a7';

export const InstaChatProjectMeta: ProjectMeta = {
  id: AppKeyInstaChat,
  pushBackgroundColor: '#C50B90',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8101,
  apiName: 'insta-chat-api',
  apiPort: 5001,
  appId: 'io.pixelteam.instachat',
  appStoreUrl: 'https://apps.apple.com/app/id6466984294',
  appName: '인스타 친구 만들기',
  appNameEn: 'SNS Friends',
  appSchemeName: 'InstaChat',
  testTargetUrl: 'https://test-ichat.pixelteam.io',
  devTargetUrl: 'https://dev-ichat.pixelteam.io',
  prodApi: 'https://ichat-api.pixelteam.io',
  devApi: 'https://dev-ichat-api.pixelteam.io',
  firebaseId: 'harvesting-chat',
  testHostname: 'test-ichat',
  clientSentryDsn: 'https://0e0fd1f3e50a49069141c11de2f47008@o190729.ingest.sentry.io/4505409153335296',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11298/deploy',
    targets: ['prod-00', 'prod-01'],
    port: 5001,
    androidAppId: '1:518115328856:android:50fd859f7d5ed5851b9b2d',
    iosAppId: '1:518115328856:ios:fe5620f1fb6f88ca1b9b2d',
    iosEncodingAppId: 'app-1-518115328856-ios-fe5620f1fb6f88ca1b9b2d',
    webAppId: '1:518115328856:web:9d76a1805db78a491b9b2d',
    kakaoAppKey: '0089f93f831eb28cb57ae8b518bd52cf',
    googleReversedId: 'com.googleusercontent.apps.518115328856-t54eskp1hj1r73vontjhu5psvclse9uh',
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    webPushKey: PROD_WEB_PUSH_KEY,
    apiUrl: 'https://ichat-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11285/deploy',
    targets: ['dev-00'],
    port: 5000,
    androidAppId: '1:255682473512:android:ec9f40243977c2a12c8f1d',
    iosAppId: '1:255682473512:ios:27f5be4214c309872c8f1d',
    iosEncodingAppId: 'app-1-255682473512-ios-27f5be4214c309872c8f1d',
    webAppId: '1:255682473512:web:ba49fdf056f968382c8f1d',
    kakaoAppKey: '1542d77cef050000b89fc2cdb8a9c6e4',
    googleReversedId: 'com.googleusercontent.apps.255682473512-1la5dun8sp25bihq4msjrm6e7srdk84q',
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    webPushKey: DEV_WEB_PUSH_KEY,
    apiUrl: 'https://dev-ichat-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:255682473512:android:31655fb11b8200902c8f1d',
    iosAppId: '1:255682473512:ios:ce2f1886c9062bc92c8f1d',
    iosEncodingAppId: 'app-1-255682473512-ios-ce2f1886c9062bc92c8f1d',
    kakaoAppKey: '1542d77cef050000b89fc2cdb8a9c6e4',
    googleReversedId: 'com.googleusercontent.apps.255682473512-tpjkeiqf05ufk2regdvdqte3dir6k6t9',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-ichat-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~4938800281',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/1132793581',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~5509289384',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/4329802560',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 인스타 친구 만들기에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'You use access to save photos to make Instagram friends.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
██╗███╗   ██╗███████╗████████╗ █████╗      ██████╗██╗  ██╗ █████╗ ████████╗
██║████╗  ██║██╔════╝╚══██╔══╝██╔══██╗    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝
██║██╔██╗ ██║███████╗   ██║   ███████║    ██║     ███████║███████║   ██║   
██║██║╚██╗██║╚════██║   ██║   ██╔══██║    ██║     ██╔══██║██╔══██║   ██║   
██║██║ ╚████║███████║   ██║   ██║  ██║    ╚██████╗██║  ██║██║  ██║   ██║   
╚═╝╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   
`
};
